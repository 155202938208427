import React from 'react'
import styled, { keyframes } from 'styled-components'

import Layout from '../components/layout'
import Seo from '../components/seo'

const ContactInnerWrap = styled.section`
  padding: 0 1rem;
  text-align: center;
`

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-8px);
  }
`

const BouncyArrow = styled.span`
  display: block;
  width: 75px;
  height: 75px;
  margin: 2rem auto 0;
  animation: ${bounce} 2s infinite;

  svg {
    width: 100%;

    .cls-1 {
      fill: none;
    }
    .cls-2 {
      fill: rgba(0, 0, 0, 0.35);
    }
  }
`

const About = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Contact"
        description="Get in touch with Gideon Caspi to discuss branding and web development projects."
      />
      <ContactInnerWrap>
        <h1>Don't Be Shy!</h1>
        <p>Get in touch with me by email at </p>
        <p style={{ marginBottom: '3rem' }}>
          <span className="hl">
            <strong>gideoncaspi{' (@) '}gmail.com</strong>
          </span>
        </p>
        <p>
          You can also find me at one of the social(ly distanced) networks
          below...
        </p>
        <BouncyArrow>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
            <g transform="translate(-306.127 377) rotate(-90)">
              <rect
                className="cls-1"
                width="21"
                height="21"
                transform="translate(356 306.126)"
              />
              <path
                className="cls-2"
                d="M2.646,6.054,6.792,1.907a1.136,1.136,0,0,0,0-1.534,1.136,1.136,0,0,0-1.534,0L.373,5.316h0a1.136,1.136,0,0,0,0,1.534l4.885,4.885a1.1,1.1,0,0,0,.742.34.916.916,0,0,0,.738-.341,1.136,1.136,0,0,0,0-1.534Z"
                transform="translate(362.91 310.05)"
              />
            </g>
          </svg>
        </BouncyArrow>
      </ContactInnerWrap>
    </Layout>
  )
}

export default About
